import React from "react";
import withAppSync from "../../AppsyncHOC";
import { useDbxWorkspacesTabs } from "./hooks/use-dbx-workspaces-tabs";
import DbxWorkspacesCard from "./dbx-workspaces-card";
import Loading from "../../../components/loading";
import DatabricksWorkspaceCreationModal from "../../../components/databricks/workspace/databricks-workspace-creation-modal";

const DbxWorkspacesTab = ({ api, playground, match }) => {
  const {
    closeCreationDialog,
    error,
    getWorkspace,
    loading,
    openCreateDialog,
    openCreationDialog,
    workspace
  } = useDbxWorkspacesTabs({ api, playground, match });

  return error ? (
    <Error error={error} path={"Workspaces"} />
  ) : loading ? (
    <Loading message="Databricks Workspaces" />
  ) : (
    <div className={"environments-container"}>
      <div>
        <div className="butn butn-flat" onClick={openCreationDialog}>
          <i className="fas fa-plus butn-icon" />
          <span className="butn-text">Register new workspace</span>
        </div>
      </div>
      <div className={"card-container"}>
        {workspace ? (
          <DbxWorkspacesCard
            uriAccount={match.params.uriAccount}
            workspace={{ ...workspace, ...{ platform: playground } }}
          />
        ) : (
          <div style={{ marginLeft: "2rem" }}>No workspaces to display.</div>
        )}
      </div>
      {openCreateDialog && (
        <DatabricksWorkspaceCreationModal
          {...{
            api,
            platform: playground,
            open: openCreateDialog,
            onClose: closeCreationDialog,
            reloadWorkspaces: getWorkspace
          }}
        />
      )}
    </div>
  );
};

export default withAppSync(DbxWorkspacesTab);

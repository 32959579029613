/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Form, Field, Formik } from "formik";
import Select from "react-select";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import "./style.less";
import { updateMessage } from "../../globalNotifications/actions";
import AutoCompletePolicies from "./components/autoCompletePolicies";
import SelectInfinite from "../../../components/SelectInfinite";
import ToggleButton from "../../../components/toggleButton";

const Log = Logger("ChooseGroupAndEnvironment");

type propTypes = {
  api: GraphQl,
  accountUri: string,
  showGlobalNotification: Function,
  callbackSuccess: Function
};

type stateTypes = {
  isFetchingPolicies: boolean,
  errorSubmit?: Object,
  error?: Object,
  groups: Array<Object>,
  policies: Array<Object>,
  selectedGroups: Array<Object>,
  attachToAllEnvironementGroups: boolean,
  totalEnvironments: Number,
  environments: Array<Object>,
  selectedEnvironment: Object,
  arn: string,
  environmentId: string,
  addToExistingProjects: boolean
};

class AddPolicy extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isFetchingPolicies: false,
      errorSubmit: undefined,
      error: undefined,
      environmentId: "",
      groups: [],
      policies: [],
      selectedGroups: [],
      attachToAllEnvironementGroups: true,
      environments: [],
      totalEnvironments: 0,
      selectedEnvironment: undefined,
      arn: "default",
      addToExistingProjects: true
    };
  }

  componentDidMount() {
    this.getEnvironments();
  }

  getEnvironments = (offset = 0) =>
    this.props.api.account
      .listPlatforms(this.props.accountUri, {
        offset: offset,
        order_by: {
          value: "asc",
          key: "name"
        }
      })
      .then((response) => {
        if (!response) return [];
        const environments = response && response.results ? response.results : [];
        this.setState((prevState) => ({
          isFetchingEnvironments: false,
          environments: prevState.environments.concat(environments),
          totalEnvironments: response.total
        }));
        return environments.map((env) => ({ value: env.uri, label: env.name }));
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error,
          Environments: [],
          isFetchingEnvironments: false
        });
      });

  getGroups = async () => {
    return (
      this.props.api.account
        // .listGroups(this.props.accountUri, { offset: 0, limit: 999 })
        .listPlatformGroups(this.state.selectedEnvironment.uri, {
          offset: 0,
          limit: 999
        })
        .then((response) => {
          if (!response) return [];
          const groups = response && response.results ? response.results : [];
          // groups = groups.filter((g) => {
          //   return g.platforms.find((i) => i.uri === this.state.selectedEnvironment.uri);
          // });
          this.setState(() => ({
            groups
          }));
          return groups;
        })
        .catch((error) => {
          Log.error(error);
          this.setState({
            error,
            groups: []
          });
        })
    );
  };

  // loadMoreGroups = (offset, search) => this.getGroups(search, offset);
  loadMoreGroups = () => { };

  getPolicies = () =>
    this.setState({ isFetchingPolicies: true }, () => {
      const options = {
        // group_uri: this.state.selectedGroup.uri,
        platform_uri: this.state.selectedEnvironment.uri,
        invert_group_relation: true
      };
      this.props.api.account
        .listPolicies(this.props.accountUri, options)
        .then((policies) => {
          this.setState({
            isFetchingPolicies: false,
            policies:
              policies.results.policies.length !== 0
                ? this.removeDuplicatePolicies(policies.results.policies)
                : []
          });
        })
        .catch((error) => {
          Log.error(error);
          this.setState({
            error,
            policies: [],
            isFetchingPolicies: false
          });
        });
    });

  removeDuplicatePolicies = (arrayPolicies) => {
    const policiesFiltered = [];
    const lookupObject = {};

    Object.keys(arrayPolicies).forEach((key) => {
      lookupObject[arrayPolicies[key].policyarn] = arrayPolicies[key];
    });

    Object.keys(lookupObject).forEach((key) => {
      policiesFiltered.push(lookupObject[key]);
    });

    return policiesFiltered;
  };

  handleGroups = (options) => {
    this.setState((prevState) => {
      const selectedGroups = (options || []).map((option) => {
        return prevState.groups.find((g) => option.value === g.uri);
      });
      return {
        selectedGroups
      };
    });
    this.getPolicies();
  };

  handleEnvironment = async (envUri) => {
    // we select the environement, then reset and fetch groups
    this.setState(
      (prevState) => {
        return {
          selectedEnvironment: prevState.environments.find(
            (i) => i.uri === envUri
          ),
          selectedGroups: [],
          groups: []
        };
      },
      async () => {
        await this.getGroups();
        if (this.state.attachToAllEnvironementGroups) {
          this.setState((prevState) => {
            return { selectedGroups: [...prevState.groups] };
          });
        } else {
          this.setState({ selectedGroups: [] });
        }
      }
    );
  };

  selectAllGroups = () => {
    this.setState((prevState) => {
      return { selectedGroups: [...prevState.groups] };
    });
  };

  handleAttachToAllEnvironementGroups = () => {
    this.setState((prevState) => {
      return {
        attachToAllEnvironementGroups: !prevState.attachToAllEnvironementGroups,
        selectedGroups: !prevState.attachToAllEnvironementGroups
          ? [...prevState.groups]
          : []
      };
    });
  };

  handleInputArn = (e, v) => {
    const newArnValue = v;
    this.setState({ arn: newArnValue });
  };

  addPolicy = async (values) => {
    this.setState({
      errorSubmit: undefined
    });

    const input = { ...values };
    input.addToExistingProjects = this.state.addToExistingProjects;

    const selectedGroupsUris = this.state.selectedGroups.map((i) => i.uri);

    try {
      await this.props.api.group.addIamPolicyToMultipleGroups(
        this.state.selectedEnvironment.uri,
        selectedGroupsUris,
        this.state.arn,
        input.addToExistingProjects,
        input.description || ""
      );
      this.props.showGlobalNotification({
        message: "Policy attached to groups",
        type: "success"
      });
      this.props.callbackSuccess();
    } catch (err) {
      this.props.showGlobalNotification({
        message: "Failed to attach policy to groups",
        type: "alert"
      });
      this.setState({
        errorSubmit: err
      });
    }
  };

  render() {
    return (
      <div className="add-policy" style={{ maxWidth: "50vw", margin: "auto" }}>
        {this.state.error && (
          <Error error={this.state.error} path="PolicyManagement" stringOnly />
        )}
        <Formik
          initialValues={{}}
          validate={() => {
            const errors = {};

            if (this.state.arn !== "default") {
              const pattern = new RegExp(
                `^arn:aws:iam::${this.state.selectedEnvironment.aws}:policy/cdh`
              );
              if (pattern.test(this.state.arn) !== true) {
                errors.policyArn = `Unable to validate policy ARN. Please verify that the ARN matches the pattern ${pattern.source}`;
              }
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            Log.info("submit !", values);
            return this.addPolicy(values).then(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {this.state.errorSubmit && (
                <Error
                  error={this.state.errorSubmit}
                  path={"addPlolicyToGroup"}
                  rawErrorMessage
                  stringOnly
                />
              )}
              {!this.state.error && (
                <React.Fragment>
                  <div className="form-group">
                    <label className="label-form">SELECT AN ENVIRONMENT</label>
                    <SelectInfinite
                      initialOptions={this.state.environments.map((env) => ({
                        label: `${env.name} (${env.aws})`,
                        value: env.uri
                      }))}
                      placeholder={"Select a environment"}
                      totalOptions={this.state.totalEnvironments}
                      loadMoreOptions={this.getEnvironments}
                      onSelectOption={(option) => {
                        this.handleEnvironment(option)
                      }}
                    />
                    {errors.selectedEnvironment && (
                      <Col size={12} style={{ color: "red" }}>
                        {errors.selectedEnvironment}
                      </Col>
                    )}
                  </div>

                  {this.state.selectedEnvironment && (
                    <div>
                      <div className="form-group">
                        <label className="label-form">
                          ATTACH TO ALL ENVIRONMENT GROUPS
                        </label>
                        <div className="" style={{ height: "30px" }}>
                          <ToggleButton
                            id="powerbi-athena-toggle"
                            checkedValue={
                              this.state.attachToAllEnvironementGroups
                            }
                            onChange={this.handleAttachToAllEnvironementGroups}
                          />
                        </div>
                      </div>

                      {this.state.attachToAllEnvironementGroups === false && (
                        <div
                          className="form-group"
                          style={{
                            background: "#00528822",
                            padding: "10px 15px"
                          }}
                        >
                          <label className="label-form">SELECT GROUPS</label>
                          <Select
                            isMulti
                            value={this.state.selectedGroups.map((g) => ({
                              value: g.uri,
                              label: g.name
                            }))}
                            options={this.state.groups.map((g) => ({
                              value: g.uri,
                              label: g.name
                            }))}
                            onChange={this.handleGroups}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}

              {!this.state.error && this.state.isFetchingPolicies && (
                <Loading message="Policies list" />
              )}
              {this.state.selectedEnvironment &&
                !this.state.isFetchingPolicies && (
                  <div>
                    <div className="container-form">
                      <AutoCompletePolicies
                        policies={this.state.policies}
                        handleInputArn={this.handleInputArn}
                        environmentId={this.state.environmentId}
                      />
                    </div>
                    <div className="container-form">
                      <div className="width100">
                        <label className="label-form">Description</label>
                        <Field
                          component="textarea"
                          name="description"
                          className="form-control bg-white"
                          placeholder="Type a description"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="width100">
                        <input
                          id="attach_to_all_projects"
                          type="checkbox"
                          className={"checkbox-facet"}
                          checked={this.state.addToExistingProjects}
                          onChange={() =>
                            this.setState((prevState) => ({
                              addToExistingProjects:
                                !prevState.addToExistingProjects
                            }))
                          }
                        />
                        <label
                          className="fas checkbox-groups"
                          htmlFor="attach_to_all_projects"
                        >
                          <span className="label-check-attach">
                            Add to all existing projects
                          </span>
                        </label>
                      </div>
                    </div>
                    {Object.keys(errors).length > 0 &&
                      Object.keys(errors).map((index) => (
                        <div className="error-msg">{errors[index]}</div>
                      ))}
                    <div className="mt-5 text-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="butn butn-check"
                      >
                        {isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Attach new policy
                      </button>
                    </div>
                  </div>
                )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(AddPolicy));

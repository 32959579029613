import React from "react";
import withGoTo from "../goToHOC";
import GraphQl from "../../graphQL";
import withAppSync from "../AppsyncHOC";
import route from "../routes";
import Logger from "../../utils/logger";
import Breadcrumb from "../../components/breadcrumb";
import config from "../../config";
import { formatDate, diffDate } from "../../utils/date";
import ButtonAction from "../../components/buttonAction";
import FormSetCredentialsModal from "./modalFormSetCredentials";
import { DateTime } from "luxon";
import DeleteObjectWithFrictionModal from "../../components/DeleteObjectWithFrictionModal";
import DatabricksWorkspaceList from "./viewDatabricksWorkspace";
import DatabricksGroupList from "./viewDatabricksGroup";
import Loading from "../../components/loading";
import Error from "../../components/error";
import { Box, TextField, IconButton } from "@material-ui/core";

const Log = Logger("DetailsView");

class DetailsDatabricksAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      databricks: {},
      credentials: {},
      isFetching: true,
      error: false,
      modalIsOpen: false,
      connectionEstablished: false,
      openDeleteModal: false,
      deleteAccountAvailable: false,
      isTestingConnection: false,
      nameAccountIsEditable: false,
      savingName: false
    };
  }

  componentDidMount() {
    this.getDetailsDatabricksAccount();
    this.getCredentialsDatabricksAccount();
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  getDetailsDatabricksAccount() {
    const { uuid } = this.props.match.params;
    const detailsAccount = this.props.api.databricks
      .getDatabricksAccount(uuid)
      .then((databricks) => {
        this.setState({
          databricks: {
            account_id: databricks.dbx_account_id,
            account_name: databricks.dbx_account_name
          }
        });
        if (databricks.dbxWorkspaces.results) {
          this.setState({
            databricks: {
              ...this.state.databricks,
              workspaces: databricks.dbxWorkspaces.results
            }
          });
        }
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, isFetching: false });
      });
  }

  getCredentialsDatabricksAccount() {
    this.setState({ isTestingConnection: true });
    const { uuid } = this.props.match.params;
    const dateNow = DateTime.now().toISO();
    const credentialsAccount = this.props.api.databricks
      .getDatabricksAccountCredentials(uuid)
      .then((credentials) => {
        this.setState({
          isFetching: false,
          connectionEstablished: true,
          credentials: {
            client_id: credentials.dbx_credentials_client_id,
            client_secret_id: credentials.dbx_credentials_client_secret_id,
            client_secret_value: this.obfuscated(
              credentials.dbx_credentials_client_secret_value
            ),
            age: Math.round(
              diffDate(
                credentials.dbx_credentials_updatedat,
                dateNow.toString()
              ) / 24
            ),
            service_principal_id: credentials.dbx_service_principal_id
          }
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error,
          isFetching: false,
          connectionEstablished: false
        });
      })
      .finally(() => {
        this.setState({ isTestingConnection: false });
      });
  }

  obfuscated = (credentials) => {
    const start = credentials.slice(0, 10);
    const end = credentials.slice(-10);
    const obfuscated = "x".repeat(credentials.length - 10);
    return `${start}${obfuscated}${end}`;
  };

  updateCrendentialsDBX() {
    this.setState({ isFetching: true });
    this.props.api.databricks
      .updateDatabricksAccountCredentialsAuto(this.props.match.params.uuid)
      .then((status) => {
        this.getCredentialsDatabricksAccount();
      });
  }

  updateManuallyCrendentialsDBX(
    client_secret_id,
    client_value,
    service_principal
  ) {
    this.setState({ isFetching: true });
    this.props.api.databricks
      .updateDatabricksAccountCredentialsManual(
        client_secret_id,
        client_value,
        service_principal,
        this.props.match.params.uuid
      )
      .then(() => {
        this.getCredentialsDatabricksAccount();
      });
  }

  actions = () => {
    const { match, goTo } = this.props;

    return (
      <div className="actions">
        <div>
          <button
            className="butn butn-flat"
            disabled
            type="button"
            onClick={() => goTo()}
          >
            Manage Groups
          </button>
        </div>
        <div>
          <ButtonAction
            tooltipText="Refresh your client secret"
            label="Refresh Credentials"
            onClick={() => this.updateCrendentialsDBX()}
            isClicked
          ></ButtonAction>{" "}
          <ButtonAction
            tooltipText="Set credentials manually"
            label="Set my credentials manually"
            onClick={() => this.openModal()}
          ></ButtonAction>
          <div
            className="butn butn-flat"
            onClick={() =>
              !this.state.isTestingConnection &&
              this.getCredentialsDatabricksAccount()
            }
            style={{ margin: "5px", width: "150" }}
          >
            {this.state.isTestingConnection ? (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            ) : (
              <i className="fas fa-sync-alt" />
            )}
            <span className="butn-text">Test Connection</span>
          </div>
          {!this.state.databricks.workspaces && (
            <button
              className="butn butn-delete"
              title="Unregister Account"
              type="button"
              onClick={() =>
                this.setState({
                  openDeleteModal: true
                })
              }
            >
              <span className="btn-text">Unregister</span>
            </button>
          )}
          {this.state.databricks.workspaces && (
            <button
              className="butn butn-delete"
              disabled
              title="You need to unregister associated workspace before"
              type="button"
              onClick={() =>
                this.setState({
                  openDeleteModal: true
                })
              }
            >
              <span className="btn-text">Unregister</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  editName = () => {
    this.setState({ savingName: true });
    const { uuid } = this.props.match.params;
    const name = this.state.databricks.account_name;
    this.props.api.databricks
      .updateDatabricksAccountName(name, uuid)
      .then(() => {
        this.getDetailsDatabricksAccount();
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ savingName: false, nameAccountIsEditable: false });
      });
  };

  deleteAccount = () => {
    const { uuid, uriAccount } = this.props.match.params;
    this.setState({ openDeleteModal: false });
    this.props.api.databricks
      .unregisterDatabricksAccount(uriAccount, uuid)
      .then(() => {
        this.props.goTo({
          route: route.Account.ViewDatabricks,
          params: {
            uriAccount: uriAccount
          }
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  };

  render() {
    const { error, isFetching, databricks, credentials } = this.state;
    return (
      <div className="playground-view">
        {error && <Error error={error} path={"User"} />}
        {isFetching && <Loading message={"User"} />}
        {databricks && credentials && !error && (
          <div>
            <div className="header">
              <div className="title-line">
                <Breadcrumb
                  view={`${this.state.databricks.account_name ||
                    ""} - Databricks Account`}
                  helpOn={{
                    content: (
                      <div>
                        <div>- Databricks Account</div>
                      </div>
                    ),
                    helpCenterLink: `${config.HELP_CENTER}`
                  }}
                />
              </div>
              {this.actions()}
            </div>
            <div>
              <ul className="info">
                <li>
                  <span className="font-weight-bold">URL :</span>{" "}
                  <a
                    href="https://accounts.cloud.databricks.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://accounts.cloud.databricks.com/
                  </a>
                </li>
                {this.state.nameAccountIsEditable ? (
                  <li>
                    <Box display="flex" flexDirection="row">
                      <TextField
                        label="Name"
                        flexGrow={1}
                        style={{ width: "50%" }}
                        defaultValue={this.state.databricks.account_name}
                        variant="outlined"
                        onChange={(event) => {
                          this.setState({
                            databricks: { account_name: event.target.value },
                            ...databricks
                          });
                        }}
                      />
                      <button
                        type={"button"}
                        className="butn butn-delete"
                        style={{ margin: "5px" }}
                        onClick={() =>
                          this.setState({ nameAccountIsEditable: false })
                        }
                      >
                        <span>{"Cancel"}</span>
                      </button>
                      <button
                        type={"submit"}
                        className="butn butn-create"
                        style={{ margin: "5px" }}
                        onClick={() => this.editName()}
                      >
                        {this.state.savingName ? (
                          <i className="fas fa-sync-alt fa-spin" />
                        ) : (
                          <i className={"fa fa-paper-plane"} />
                        )}
                        <span>{" Edit"}</span>
                      </button>
                    </Box>
                  </li>
                ) : (
                  <li>
                    <span className="font-weight-bold">Name :</span>{" "}
                    {this.state.databricks.account_name}{" "}
                    <IconButton
                      size="small"
                      component="span"
                      onClick={() =>
                        this.setState({ nameAccountIsEditable: true })
                      }
                    >
                      <i class="far fa-edit"></i>
                    </IconButton>
                  </li>
                )}

                <li>
                  <span className="font-weight-bold">Account ID :</span>{" "}
                  {this.state.databricks.account_id}
                </li>
                <li>
                  <span className="font-weight-bold">
                    Service principal - Client ID:
                  </span>{" "}
                  {this.state.credentials.client_id}
                </li>
                <li>
                  <span className="font-weight-bold">
                    Service principal - Client Secret:
                  </span>{" "}
                  {this.state.credentials.client_secret_value}{" "}
                </li>
                <li>
                  <span className="font-weight-bold">
                    Service principal - Credential age:
                  </span>{" "}
                  {this.state.credentials.age} days
                </li>
                <li>
                  <span className="font-weight-bold">Connection status:</span>{" "}
                  <span className="loader-status">
                    {this.state.isTestingConnection ? (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    ) : (
                      <span
                        className={`status-${this.state.connectionEstablished ? "green" : "red"
                          }`}
                      >
                        {this.state.connectionEstablished
                          ? "AVAILABLE"
                          : "UNAVAILABLE"}
                      </span>
                    )}
                  </span>{" "}
                </li>
              </ul>
            </div>
            {this.state.isFetching && (
              <Loading message={"Databricks Workspace View"} />
            )}
            {!this.state.isFetching && (
              <div className="">
                <DatabricksWorkspaceList
                  uriAccount={this.props.match.params.uriAccount}
                  uuid={this.props.match.params.uuid}
                />
              </div>
            )}

            {this.state.isFetching && (
              <Loading message={"Databricks Instance View"} />
            )}
            {!this.state.isFetching && (
              <div className="">
                <DatabricksGroupList
                  uriAccount={this.props.match.params.uriAccount}
                  uuid={this.props.match.params.uuid}
                />
              </div>
            )}
          </div>
        )}
        {this.state.modalIsOpen && (
          <FormSetCredentialsModal
            onClose={this.closeModal}
            updateManuallyCrendentials={(
              client_secret_id,
              client_value,
              service_principal
            ) => {
              this.updateManuallyCrendentialsDBX(
                client_secret_id,
                client_value,
                service_principal
              );
            }}
          />
        )}
        {this.state.openDeleteModal && !this.state.databricks.workspaces && (
          <DeleteObjectWithFrictionModal
            objectName={`Databricks Account ${this.state.databricks.account_name}`}
            deleteMessage={"Delete Databricks Account ?"}
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => {
              this.setState({ openDeleteModal: false });
            }}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteAccount}
            isAWSResource={false}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(DetailsDatabricksAccount);

/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../globalNotifications/actions";
import Col from "../../../components/col";
import Row from "../../../components/row";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import withGoTo from "../../goToHOC";
import Logger from "../../../utils/logger";
import DatasourceForm from "../components/form";
import Routes from "../../routes";
import GraphQl from "../../../graphQL";

const Log = Logger("DatasourceEdit");

type propTypes = {
  match: {
    params: {
      uriDatasource: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  ready: boolean,
  datasource: Object,
  group: Object,
  error?: Object,
  errorSubmit?: Object
};

class DatasourceEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      datasource: {},
      group: {},
      error: undefined,
      errorSubmit: undefined
    };
  }

  componentDidMount() {
    this.props.api.datasource
      .getDatasourceForEdit(this.props.match.params.uriDatasource)
      .then((datasource) => {
        this.setState({
          datasource: {
            ...datasource,
            details: datasource.details ? datasource.details : "",
            owneruri: datasource.owner.uri,
            platformUri: datasource.platform.uri,
            sharingManagementGroupUri: datasource.sharingManagementGroup.uri,
            network: datasource.RSDatasource
              ? datasource.RSDatasource.network
              : null,
            workgroup_base_capacity: datasource.RSDatasource
              ? datasource.RSDatasource.workgroup_base_capacity
              : null,
            namespace: datasource.RSDatasource
              ? datasource.RSDatasource.rs_namespace_uri
              : null
          },
          group: datasource.owner,
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const datasource = Object.assign({}, prevState.datasource);
      datasource[field] = value;
      return { datasource };
    });
  };

  save = () => {
    const datasource = Object.assign({}, this.state.datasource);
    datasource.name = (this.state.datasource.name || "").trim();
    datasource.sharing_group_uri = datasource.sharingManagementGroupUri;
    if (!datasource.s3_custom_tags) {
      delete datasource.s3_custom_tags;
    }
    delete datasource.sharingManagementGroup;
    delete datasource.sharingManagementGroupUri;
    delete datasource.platformUri;
    delete datasource.region;
    delete datasource.resource_name;
    delete datasource.owner;
    delete datasource.platform;
    delete datasource.uri;
    delete datasource.accountid;
    delete datasource.bucket;
    delete datasource.bucket_owner_enforced;
    delete datasource.namespace;
    delete datasource.network;
    delete datasource.workgroup_base_capacity;
    delete datasource.source_type;
    delete datasource.RSDatasource;
    delete datasource.database;
    delete datasource.tags;
    return this.props.api.datasource.update(
      this.props.match.params.uriDatasource,
      datasource
    );
  };

  onSubmit = () =>
    this.save()
      .then((datasource) => {
        this.props.showGlobalNotification({
          message: "Datasource updated",
          type: "success"
        });
        return this.props.goTo({
          route: Routes.Datasource.View,
          params: {
            uriAccount: this.state.group.account.uri,
            uriDatasource: datasource.uri
          },
          state: {
            account: { uri: this.state.group.account.uri },
            playground: { uri: this.state.datasource.platform.uri },
            datasource
          }
        });
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.props.showGlobalNotification({
          message: "Datasource update failed",
          type: "alert"
        });
        this.setState({ errorSubmit });
      });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasourceEdit"} />;
    if (!this.state.ready) {
      return <Loading className={"my-3"} message={"datasource update"} />;
    }
    return (
      <div className="datasource">
        <Breadcrumb view={"Update datasource"} />

        <Row>
          <Col size={1} />
          <Col size={10}>
            {this.state.errorSubmit && (
              <Error
                title={"..."}
                error={this.state.errorSubmit}
                displayError
                path={"DatasourceEdit"}
                stringOnly
              />
            )}

            <DatasourceForm
              handleChange={this.handleChange}
              datasource={this.state.datasource}
              group={this.state.group}
              submit={this.onSubmit}
              errorSubmit={this.state.errorSubmit}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceEdit)));

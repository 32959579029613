/* @flow */
import React from "react"
import { connect } from "react-redux"
import { updateMessage } from "../../globalNotifications/actions"
import Loading from "../../../components/loading"
import Error from "../../../components/error"
import withAppSync from "../../AppsyncHOC"
import Modal from "../../../components/modal"
import Breadcrumb from "../../../components/breadcrumb"
import DatasetInfo from "../components/datasetInfo"
import DatasetShares from "../components/datasetShares"
import Logger from "../../../utils/logger"
import Tabs from "../../../components/tabs"
import "./style.less"
import DatasetActions from "./DatasetActions"
// import DatasetPreview from '../preview';
import DatasetUploadData from "../uploadData"
import { getErrorMessageWithoutCode } from "../../../utils/error"
import { formatDate } from "../../../utils/date"
import NewStageCardList from "../../stages/components/card/newStageCardList"
import GraphQl from "../../../graphQL"
import DatasetSchemaDetection from "../components/datasetSchemaStatus/datasetSchemaStatus"
import DefaultStageMetadata from "../components/defaultStageMetadata"
import DatasetDetails from "./DatasetDetails"
import Links from "../../links"
import DatasetUsage from "../components/datasetUsage/datasetUsage"
import routes from "../../routes"
import withGoTo from "../../goToHOC"
import { getAccountUriFromUri } from "../../../utils/toolsForUri"
import CollibraDatasets from "../../collibra/CollibraDatasets"
import logoCollibra from "../../collibra/collibra-logo.png"
import logoRedshift from "../../logos/redshift-logo.png"
import DLLogo from "../../logos/deltalake-logo.png"
import logoS3 from "../../logos/s3-logo.png"
import { isRSSourceType, isS3SourceType } from "../../../utils/constants"

const Log = Logger("DatasetView")

type propTypes = {
  match: {
    params: {
      uriDataset: string
    },
    url: string
  },
  goTo: Function,
  location: {
    hash: string,
    state: Object
  },
  api: GraphQl,
  showGlobalNotification: Function
}

type stateType = {
  ready: boolean,
  dataset: Object,
  datasetModal: boolean,
  error?: Object,
  errorDatasetModal?: Object | string,
  confirmDeletion: boolean,
  errorConfirmDeletion: boolean,
  publisher: Object,
  isDeleting: boolean,
  isPublicDatasetView: boolean,
  showCollibra: boolean,
  showTitleCollibraLogo: boolean,
  collibraAssetUrl: string
}

class DatasetView extends React.Component<propTypes, stateType> {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      dataset: {
        creator: {},
        owner: {},
        details: {},
        datasource: {},
        resource_name: "",
        access: "",
        uri: "",
        name: "",
        stages: [],
        can_share: false,
        sharesList: { total: 0 },
        shareable: true,
        isPublicDatasetView: false
      },
      datasetFormat: null,
      collibraAssetUrl: "",
      error: false,
      publisher: {},
      datasetModal: false,
      errorDatasetModal: "",
      confirmDeletion: false,
      errorConfirmDeletion: false,
      isDeleting: false,
      isPublicDatasetView: false,
      showCollibra: false,
      showTitleCollibraLogo: false
    }
  }

  componentDidMount() {
    const isCollibraFeatureEnabled = this.isCollibraFeatureEnabled()
    const isCollibraLinkAvailable = this.isCollibraLinkAvailable()
    const isPublishedOnCollibra = this.isPublishedOnCollibra()
    const a = this.getDataset()
    const b = this.getPublishers()

    this.getDatasetFormat()

    return Promise.all([
      a,
      b,
      isCollibraLinkAvailable,
      isCollibraFeatureEnabled,
      isPublishedOnCollibra
    ]).then((items) => {
      const dataset = items[0]
      const publishers = items[1]
      const accountFound = publishers.find(
        (publisher) => publisher.accountid === dataset.accountid
      )
      this.setState({
        isPublicDatasetView: dataset.isPublicDatasetView,
        dataset,
        publisher: accountFound,
        showCollibra: items[2] && items[3],
        ready: true
      })
    })
  }

  isCollibraFeatureEnabled() {
    const accountUri = getAccountUriFromUri(this.props.match.params.uriDataset)
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("COLLIBRA", accountUri)
      .then((showCollibra) => {
        return showCollibra
      })
      .catch((error) => {
        this.setState({
          error
        })
      })
  }

  isCollibraLinkAvailable() {
    const accountUri = getAccountUriFromUri(this.props.match.params.uriDataset)
    return this.props.api.collibra
      .list(accountUri)
      .then((links) => {
        return links && links.length > 0
      })
      .catch((error) => {
        this.setState({
          error
        })
      })
  }

  isPublishedOnCollibra = () => {
    this.props.api.collibra
      .listCollibraDatasets(this.props.match.params.uriDataset)
      .then((collibraDatasets) => {
        this.setState({
          showTitleCollibraLogo: !!(
            collibraDatasets && collibraDatasets.length > 0
          ),
          collibraAssetUrl:
            collibraDatasets && collibraDatasets.length > 0
              ? collibraDatasets[0].asset_url
              : ""
        })
      })
      .catch((error) => {
        this.setState({
          error
        })
      })
  }

  getPublishers() {
    return this.props.api.account
      .getAllAccounts()
      .then((publishers) => {
        Log.verbose("getPublishers", publishers)
        return publishers
      })
      .catch((err) => {
        Log.error(err)
        return []
      })
  }

  async getDataset() {
    let datasetResponse
    let isPublicDatasetView = false
    let errorGetDataset = false
    try {
      datasetResponse = await this.props.api.dataset.get(
        this.props.match.params.uriDataset
      )
    } catch (error) {
      if (
        error.message &&
        error.message.includes("Forbidden Operation Error")
      ) {
        const publicDatasetData = await this.props.api.dataset.getDatasetPublic(
          this.props.match.params.uriDataset
        )
        datasetResponse = publicDatasetData
        isPublicDatasetView = true
      } else {
        datasetResponse = {}
        errorGetDataset = true
        this.setState({
          error
        })
      }
    }

    let details = {}
    try {
      details = JSON.parse(datasetResponse.details)
    } catch (error) {
      details = {}
    }

    if (
      !isPublicDatasetView &&
      !errorGetDataset &&
      !datasetResponse.shareable &&
      datasetResponse.sharesList.total > 0
    ) {
      this.props.showGlobalNotification({
        message:
          'Warning: Your dataset is already shared with other group(s). You can revoke all shares manually from "shares" tab',
        type: "warning",
        popupDuration: 10000
      })
    }
    // $FlowIgnore
    return { ...datasetResponse, details, isPublicDatasetView }
  }

  getDatasetFormat = async () => {
    try {
      const response = await this.props.api.dataset.listStages(
        this.props.match.params.uriDataset,
        {
          offset: 0,
          limit: 1
        }
      )
      if (response.results[0].data_format_type) {
        this.setState({ datasetFormat: response.results[0].data_format_type })
      } else {
        this.setState({ datasetFormat: "" })
      }
    } catch (error) {
      this.setState({ datasetFormat: "" })
    }
  }

  openDatasetModal = () =>
    this.setState({
      errorDatasetModal: "",
      datasetModal: true
    })

  closeDatasetModal = () => this.setState({ datasetModal: false })

  deleteDataset = () => {
    if (!this.state.confirmDeletion) {
      this.setState({ errorConfirmDeletion: true })
    } else {
      this.setState({ isDeleting: true })
      this.props.api.dataset
        .deleteDataset(this.state.dataset.uri)
        .then((response) => {
          if (response === "null") {
            this.props.showGlobalNotification({
              message: "The dataset was deleted on CDH",
              type: "success"
            })
          } else {
            this.props.showGlobalNotification({
              message:
                "The dataset was deleted on CDH and will be deleted on S3 at midnight",
              type: "success"
            })
          }
          this.setState({ isDeleting: false })
          this.closeDatasetModal()
          this.props.goTo({
            route: routes.Datasource.View,
            params: {
              uriAccount: this.state.dataset.accountid,
              uriDatasource: this.state.dataset.datasource.uri
            }
          })
        })
        .catch((err) => {
          Log.error(err)
          const errorMessage = getErrorMessageWithoutCode(err)
          this.setState({ isDeleting: false })
          if (errorMessage.includes("Cannot delete a shared dataset")) {
            this.setState({
              errorDatasetModal:
                "You can't delete the dataset because it's shared to others groups. Please revoke shared access before delete this dataset."
            })
          } else {
            this.setState({ errorDatasetModal: errorMessage })
          }
          this.props.showGlobalNotification({
            message: "Dataset deletion failed",
            type: "alert"
          })
        })
    }
  }

  onChangeValidationDelete = (event) => {
    const { value } = event.target
    if (value === "delete" || value === "DELETE") {
      this.setState({
        confirmDeletion: true
      })
    } else {
      this.setState({
        confirmDeletion: false
      })
    }
  }

  bodyDatasetModal() {
    return (
      <div>
        <span>Are you sure you want to delete this dataset ?</span>
        <div>"Type DELETE to confirm"</div>
        <div className="mt-2">
          <input
            onChange={this.onChangeValidationDelete}
            className="form-control bg-white"
            placeholder="DELETE"
          />
        </div>
        {this.state.errorConfirmDeletion && (
          <span className="error-confirm">
            Please enter the text exactly as displayed to confirm
          </span>
        )}
      </div>
    )
  }

  datasetModal = () => {
    if (!this.state.datasetModal) return false
    return (
      <Modal
        title={`Delete dataset ${this.state.dataset.name}`}
        body={this.bodyDatasetModal()}
        errorMessage={this.state.errorDatasetModal}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={this.closeDatasetModal}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={this.deleteDataset}
          >
            {this.state.isDeleting && (
              <i className="mr-2 fas fa-circle-notch fa-spin" />
            )}
            Delete
          </button>
        ]}
      />
    )
  }

  getTabs = () => {
    const tabs = [
      {
        name: "Information",
        index: "information",
        route: `${this.props.match.url}#information`,
        content: (
          <div id="nav-information">
            <DatasetInfo api={this.props.api} dataset={this.state.dataset} />
          </div>
        )
      },
      {
        name: "Stages",
        index: "stages",
        route: `${this.props.match.url}#stages`,
        content: (
          <div id="nav-stages">
            <NewStageCardList dataset={this.state.dataset} />
          </div>
        )
      }
    ]

    if (this.state.isPublicDatasetView) {
      return [tabs[0]]
    }

    tabs.push(
      {
        name: "Metadata",
        index: "metadata",
        route: `${this.props.match.url}#metadata`,
        content: (
          <div id="nav-metadata">
            {this.state.dataset.stages.length > 0 ? (
              <DefaultStageMetadata
                dataset={this.state.dataset}
                outputId={
                  (
                    this.state.dataset.stages.find((stage) => stage.is_default) ||
                    {}
                  ).id
                }
              />
            ) : (
              <div>No metadata to display</div>
            )}
          </div>
        )
      },
      {
        name: "Shares",
        index: "shares",
        route: `${this.props.match.url}#shares`,
        content: (
          <div id="nav-shares">
            <DatasetShares
              dataset={this.state.dataset}
              api={this.props.api}
              uriDataset={this.state.dataset.uri}
              goTo={this.props.goTo}
            />
          </div>
        )
      }
    )

    if (
      this.state.dataset.access === "owner" &&
      isS3SourceType(this.state.dataset.source_type)
    ) {
      tabs.push({
        name: "Upload",
        index: "upload",
        route: `${this.props.match.url}#upload`,
        content: (
          <div id="nav-upload">
            <DatasetUploadData dataset={this.state.dataset} />
          </div>
        )
      })

      tabs.push({
        name: "Schema Status",
        index: "schemastatus",
        route: `${this.props.match.url}#schemastatus`,
        content: (
          <div id="nav-schemaDetection">
            <DatasetSchemaDetection dataset={this.state.dataset} />
          </div>
        )
      })
    }
    if (this.state.dataset.access === "owner") {
      tabs.push({
        name: "Usage",
        index: "usage",
        route: `${this.props.match.url}#usage`,
        content: (
          <div id="nav-usage">
            <DatasetUsage dataset={this.state.dataset} />
          </div>
        )
      })
    }
    if (this.state.showCollibra) {
      tabs.push({
        name: (
          <span>
            Collibra<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "collibra",
        route: `${this.props.match.url}#collibra`,
        content: (
          <div id="nav-usage">
            <CollibraDatasets
              api={this.props.api}
              dataset={this.state.dataset}
              isPublishedOnCollibra={this.isPublishedOnCollibra}
            />
          </div>
        )
      })
    }
    return tabs
  }

  goToRequestAccess(e, goTo) {
    e.preventDefault()
    goTo({
      route: routes.Share.Request,
      params: { uriDataset: this.state.dataset.uri },
      state: { dataset: this.state.dataset }
    })
  }

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} path={"DatasetView"} />
    }
    if (!this.state.ready) return <Loading message={"dataset view"} />

    // public view
    if (this.state.isPublicDatasetView) {
      return (
        <React.Fragment>
          <div className="dataset-top">
            <div
              className="bread-line"
              style={{ display: "flex", justifyContent: "start" }}
            >
              <div style={{ width: "500px" }}>
                <div className={"title-line"}>
                  <Breadcrumb
                    state={this.props.location.state}
                    view={
                      <span>
                        {this.state.datasetFormat !== null ? (
                          this.state.datasetFormat === "DELTA" ? (
                            <img
                              className="connector-logo-img mr-1"
                              src={DLLogo}
                              alt="S3"
                              width={43}
                              height={41}
                            />
                          ) : isS3SourceType(this.state.dataset.source_type) ? (
                            <img
                              className="connector-logo-img mr-2"
                              src={logoS3}
                              alt="S3"
                              width={43}
                              height={41}
                            />
                          ) : (
                            isRSSourceType(this.state.dataset.source_type) && (
                              <img
                                className="connector-logo-img mr-2"
                                src={logoRedshift}
                                alt="Redshift Serverless"
                                width={43}
                                height={41}
                              />
                            )
                          )
                        ) : (
                          <i className="fas fa-sync-alt fa-spin" />
                        )}
                        {this.state.dataset.name || ""}{" "}
                        {this.state.showTitleCollibraLogo && (
                          <img
                            className="connector-logo-img mr-1"
                            src={logoCollibra}
                            alt="Collibra"
                            width={22}
                            height={22}
                          />
                        )}
                      </span>
                    }
                  />
                </div>
                <div className="dataset-info-owner">
                  <span className="text-uppercase">
                    Dataset by&nbsp;
                    <span className="dataset-info-detail">
                      {this.state.publisher.name}
                    </span>
                  </span>
                  <span className="text-uppercase">
                    <i className="fas fa-user-friends dataset-info-team" />
                    Owner Group&nbsp;
                    <span className="dataset-info-detail">
                      <Links.Group.View
                        uriGroup={(this.state.dataset.owner || {}).uri}
                      >
                        {this.state.dataset.owner.name}
                      </Links.Group.View>
                    </span>
                  </span>
                </div>
              </div>
              {this.state.dataset.shareable && (
                <div
                  style={{
                    maxWidth: "600px",
                    display: "flex",
                    gap: "32px",
                    alignItems: "center",
                    marginTop: "16px",
                    paddingBottom: "16px"
                  }}
                >
                  <i
                    className="fa fa-lock"
                    style={{ fontSize: "32px", color: "var(--blue-venice)" }}
                  />
                  <h2 style={{ lineHeight: "35px" }}>
                    You don't have access to this dataset yet, please{" "}
                    <u>
                      <a
                        href=""
                        onClick={(e) =>
                          this.goToRequestAccess(e, this.props.goTo)
                        }
                      >
                        request access
                      </a>
                    </u>{" "}
                    to ask for more rights.
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="dataset-body">
            <div className="dataset-body-left">
              <DatasetDetails dataset={this.state.dataset} />
            </div>
            <div className="dataset-body-right">
              <Tabs
                isPreload={false}
                defaultActiveIndex={"information"}
                tabs={this.getTabs()}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }

    // non public view
    return (
      <React.Fragment>
        <div className="dataset-top">
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                state={this.props.location.state}
                view={
                  <span>
                    {this.state.datasetFormat !== null ? (
                      this.state.datasetFormat === "DELTA" ? (
                        <img
                          className="connector-logo-img mr-1"
                          src={DLLogo}
                          alt="S3"
                          width={43}
                          height={41}
                        />
                      ) : isS3SourceType(this.state.dataset.source_type) ? (
                        <img
                          className="connector-logo-img mr-2"
                          src={logoS3}
                          alt="S3"
                          width={43}
                          height={41}
                        />
                      ) : (
                        isRSSourceType(this.state.dataset.source_type) && (
                          <img
                            className="connector-logo-img mr-2"
                            src={logoRedshift}
                            alt="Redshift Serverless"
                            width={43}
                            height={41}
                          />
                        )
                      )
                    ) : (
                      <i className="fas fa-sync-alt fa-spin" />
                    )}
                    {this.state.dataset.name || ""}{" "}
                    {this.state.showTitleCollibraLogo && (
                      <a href={this.state.collibraAssetUrl} target="blank">
                        <img
                          className="connector-logo-img mr-1"
                          src={logoCollibra}
                          alt="Collibra"
                          width={22}
                          height={22}
                        />
                      </a>
                    )}
                  </span>
                }
              />
            </div>
            <div className="dataset-info-owner">
              <span className="text-uppercase">
                Dataset by&nbsp;
                <span className="dataset-info-detail">
                  {this.state.publisher.name}
                </span>
              </span>
              <span className="text-uppercase">
                <i className="fas fa-user-friends dataset-info-team" />
                Owner Group&nbsp;
                <span className="dataset-info-detail">
                  <Links.Group.View
                    uriGroup={(this.state.dataset.owner || {}).uri}
                  >
                    {this.state.dataset.owner.name}
                  </Links.Group.View>
                </span>
              </span>
            </div>
          </div>
          <div className="create-container mt-1">
            {!!this.state.dataset.expiration_date && (
              <div className="deletionTextContainer mt-2">
                <i className="fas fa-exclamation-triangle" />
                <span className="deletionText">
                  {`Warning: this dataset will be permanently deleted on ${formatDate(
                    this.state.dataset.expiration_date,
                    false
                  )}`}{" "}
                </span>
              </div>
            )}
            <DatasetActions
              dataset={this.state.dataset}
              openDatasetModal={this.openDatasetModal}
              goTo={this.props.goTo}
            />
          </div>
        </div>
        <div className="dataset-body">
          <div className="dataset-body-left">
            <DatasetDetails dataset={this.state.dataset} />
          </div>
          <div className="dataset-body-right">
            <Tabs
              isPreload={false}
              defaultActiveIndex={"information"}
              tabs={this.getTabs()}
            />
          </div>
        </div>
        {this.datasetModal()}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value))
  }
})

export default withAppSync(
  connect(null, mapDispatchToProps)(withGoTo(DatasetView))
)

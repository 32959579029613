/* @flow */

import CartApi from "./cart";
import DatasourceApi from "./datasource";
import DatasetApi from "./dataset";
import AccountApi from "./account";
import GroupApi from "./group";
import ShareApi from "./share";
import PlaygroundApi from "./playgrounds";
import IdentityApi from "./identity";
import AthenaApi from "./athena";
import UserApi from "./user";
import NotificationApi from "./notification";
import NewtificationApi from "./newtification";
import SagemakerNotebookInstanceApi from "./sagemakerNotebookInstance";
import AnalyticsApi from "./analytics";
import AdminApi from "./admin";
import UtilsApi from "./utils";
import ExportCSVApi from "./csvExport";
import FeatureToggleApi from "./featureToggle";
import AccelerationTemplatesApi from "./accelerationTemplates";
import KpiApi from "./kpi";
import LogGroupsApi from "./log-groups";
import DssInstanceApi from "./dssinstance";
import SqlLabApi from "./sqllab";
import CollibraApi from "./collibra";
import SmStudioApi from "./smstudio";
import DatabricksApi from "./databricks";
import CatalogAPI from './catalog'

class GraphQl {
  cart: CartApi;

  datasource: DatasourceApi;

  dataset: DatasetApi;

  account: AccountApi;

  identity: IdentityApi;

  group: GroupApi;

  share: ShareApi;

  playground: PlaygroundApi;

  athena: AthenaApi;

  user: UserApi;

  notification: NotificationApi;

  newtification: NewtificationApi;

  sagemakerNotebookInstance: SagemakerNotebookInstanceApi;

  analytics: AnalyticsApi;

  admin: AdminApi;

  utils: UtilsApi;

  exportCSV: ExportCSVApi;

  featureToggle: FeatureToggleApi;

  accelerationTemplates: AccelerationTemplatesApi;

  kpi: KpiApi;

  logGroups: LogGroupsApi;

  dssInstance: DssInstanceApi;

  sqlLab: SqlLabApi;

  collibra: CollibraApi;

  smstudio: SmStudioApi;

  databricks: DatabricksApi;

  constructor(fetchAppSync: Function) {
    this.cart = new CartApi(fetchAppSync);
    this.datasource = new DatasourceApi(fetchAppSync);
    this.dataset = new DatasetApi(fetchAppSync);
    this.account = new AccountApi(fetchAppSync);
    this.identity = new IdentityApi(fetchAppSync);
    this.group = new GroupApi(fetchAppSync);
    this.share = new ShareApi(fetchAppSync);
    this.playground = new PlaygroundApi(fetchAppSync);
    this.athena = new AthenaApi(fetchAppSync);
    this.user = new UserApi(fetchAppSync);
    this.notification = new NotificationApi(fetchAppSync);
    this.newtification = new NewtificationApi(fetchAppSync);
    this.sagemakerNotebookInstance = new SagemakerNotebookInstanceApi(
      fetchAppSync
    );
    this.analytics = new AnalyticsApi(fetchAppSync);
    this.admin = new AdminApi(fetchAppSync);
    this.utils = new UtilsApi(fetchAppSync);
    this.exportCSV = new ExportCSVApi(fetchAppSync);
    this.featureToggle = new FeatureToggleApi(fetchAppSync);
    this.accelerationTemplates = new AccelerationTemplatesApi(fetchAppSync);
    this.kpi = new KpiApi(fetchAppSync);
    this.logGroups = new LogGroupsApi(fetchAppSync);
    this.dssInstance = new DssInstanceApi(fetchAppSync);
    this.sqlLab = new SqlLabApi(fetchAppSync);
    this.collibra = new CollibraApi(fetchAppSync);
    this.smstudio = new SmStudioApi(fetchAppSync);
    this.databricks = new DatabricksApi(fetchAppSync);
    this.catalog = new CatalogAPI(fetchAppSync);
  }
}

export default GraphQl;

import React, { useState, useEffect } from "react";
import ChatBot from "react-chatbotify";
import withAppSync from "../../containers/AppsyncHOC";
import GraphQl from "../../graphQL";
import logoBedrock from "../../containers/catalog/Arch_Amazon-Bedrock_64.svg";
import AvatarBedrock from "../../containers/catalog/amazon-bedrock-avatar.svg";
import SourceList from "./SourceList";
import config from "../../config";

const GenAIChatBot = ({ api }) => {
  const run = async (cmd, prompt) => {
    let command = {};
    command[cmd] = prompt;
    const result = await api.catalog.askGenAICatalogChatBot(
      JSON.stringify(command)
    );
    const result_body = JSON.parse(result.searchDatasetResultsV2).body;
    return result_body;
  };

  const settings = {
    chatHistory: { disabled: true },
    botBubble: {
      simStream: false,
      showAvatar: true,
      avatar: AvatarBedrock
    },
    tooltip: {
      mode: "never"
    },
    emoji: {
      disabled: true
    },
    fileAttachment: {
      disabled: true
    },
    chatInput: {
      enabledPlaceholderText: "Type your question here"
    },
    notification: {
      disabled: true
    },
    header: {
      title: "CDH Catalog AI Bot"
    },
    chatButton: {
      icon: logoBedrock
    },
    chatWindow: {
      showScrollbar: true
    },
    chatButtonStyle: { bottom: "70px", right: "10px" },
    chatWindowStyle: { width: "700px", bottom: "70px" },
    botBubbleStyle: { backgroundColor: "#fafafa", color: "#16191f" },
    userBubbleStyle: { backgroundColor: "#0073bb", color: "#fafafa" }
  };

  const Balise = (url, name) => {
    return <a href={url}>{name}</a>;
  };

  const BotResponse = ({ answer, urlList }) => {
    let parts = [];

    const Render = () => {
      const response = [answer];

      Object.keys(urlList).length > 0
        ? Object.keys(urlList).forEach((key) => {
            response.forEach((element, index) => {
              let tmp = element;

              while (typeof tmp === "string" && tmp.search(key) !== -1) {
                const [before, after] = tmp.split(key);

                response.splice(index, 1, before);
                response.splice(
                  index + 1,
                  0,
                  <a key={index} target="_blank" href={urlList[key].url}>
                    {urlList[key].name}
                  </a>
                );
                response.splice(index + 2, 0, after);
                tmp = after;
              }
            });
          })
        : answer;

      return (
        <div
          className="rcb-bot-message rcb-bot-message-entry"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            color: "rgb(0, 0, 0)",
            maxWidth: "65%",
            borderRadius: "15px 15px 15px 0px",
            textAlign: "left",
            border: "2px double rgb(1, 79, 134)",
            padding: "10px 15px",
            display: "inline"
          }}
        >
          {response}
        </div>
      );
    };

    return <Render />;
  };

  const bedrockStream = async (params) => {
    try {
      if (params.userInput.toLowerCase() == "clear history") {
        const result_body = await run("clear_history", "");
        if (result_body === "Success") {
          return "History cleared";
        } else {
          return "Failed to clear history";
        }
      } else {
        const result_body = await run("question", params.userInput);
        const { answer, url_mapping } = result_body;
        let urls = [{}];
        result_body.context.forEach((link) => {
          const url = link.metadata.uri;
          const { name } = link.metadata;
          urls.push({
            url: `${config.DOMAINE_NAME}/${url}/view`,
            name: name
          });
        });
        await params.streamMessage(
          <BotResponse answer={answer} urlList={url_mapping} />
        );
        await params.injectMessage(<SourceList sources={urls} />);
      }
    } catch (error) {
      await params.injectMessage("Unable to load answer");
      hasError = true;
    }
  };

  const HistoryBotResponse = async (params) => {
    const result_body = await run("get_history", "");
    let i = 0;
    for (let message of result_body) {
      if (i % 2 == 0) {
        await params.injectMessage(message.content, "user");
      } else {
        await params.injectMessage(
          <BotResponse answer={message.content} urlList={message.uuid_dict} />,
          "bot"
        );
      }
      i++;
    }
    await params.injectMessage(
      'Hello, ask me about commonDataHub Catalog! \nType "clear history" to clear the history.'
    );
  };

  const flow = {
    start: {
      message: async (params) => {
        await HistoryBotResponse(params);
      },
      path: "loop"
    },
    loop: {
      message: async (params) => {
        await bedrockStream(params);
      },
      path: "loop"
    }
  };

  const themes = [{ id: "minimal_midnight", version: "0.1.0" }];

  return <ChatBot settings={settings} flow={flow} themes={themes} />;
};

export default withAppSync(GenAIChatBot);

/* @flow */
import React, { useEffect, useState } from "react"
import withGoTo from "../../goToHOC"
import stringcutter from "../../../components/stringcutter"
import DatasetCardPreviewAction from "./DatasetCardPreviewAction"
import RSLogo from "../../logos/redshift-logo.png"
import S3Logo from "../../logos/s3-logo.png"
import DLLogo from "../../logos/deltalake-logo.png"

const DatasetCardTitle = ({
  api,
  name,
  uri,
  access,
  defaultTable,
  type,
  goTo,
  sourceType = null,
  size = 50,
  withDeltaLakeLogo = true
}) => {
  const [datasetFormat, setDatasetFormat] = useState(null)

  const getDatasetFormat = async () => {
    try {
      const response = await api.dataset.listStages(uri, {
        offset: 0,
        limit: 1
      })
      if (response.results[0].data_format_type) {
        setDatasetFormat(response.results[0].data_format_type)
      } else {
        setDatasetFormat("")
      }
    } catch (error) {
      setDatasetFormat("")
    }
  }

  useEffect(() => {
    if (datasetFormat === null) {
      if (withDeltaLakeLogo === false) {
        setDatasetFormat("")
        return
      }
      getDatasetFormat()
    }

  }, [datasetFormat])

  return (
    <div className={"dataset-card-name"}>
      {datasetFormat !== null ? (
        datasetFormat === "DELTA" ? (
          <img
            className="connector-logo-img mr-1"
            src={DLLogo}
            alt="S3"
            width={28}
            height={28}
          />
        ) : sourceType && sourceType === "S3" ? (
          <img
            className="connector-logo-img mr-1"
            src={S3Logo}
            alt="S3"
            width={28}
            height={28}
          />
        ) : (
          sourceType &&
          (sourceType === "REDSHIFT_SERVERLESS" ||
            sourceType === "Redshift Serverless") && (
            <img
              className="connector-logo-img mr-1"
              src={RSLogo}
              alt="Redshift Serverless"
              width={28}
              height={28}
            />
          )
        )
      ) : (
        <i className="fas fa-sync-alt fa-spin" />
      )}

      {stringcutter(name, size)}
      <DatasetCardPreviewAction
        uri={uri}
        access={access}
        name={name}
        defaultTable={defaultTable}
        type={type}
        goTo={goTo}
      />
    </div>
  )
}

DatasetCardTitle.defaultProps = {
  name: "-"
}

export default withGoTo(DatasetCardTitle)

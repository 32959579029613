export const listDatabricksAccountGroups = {
  query: `query Q ($uuid:String!, $account_uri:String) {
        listDatabricksAccountGroups(uuid: $uuid, accountUri: $account_uri) {
            limit
            offset
            total
            results {
                dbx_group_name
                dbx_group_id
                dbx_account_uuid
                is_account_admin
                cdh_group_uri
            }
        }
      }
    `,
  keyOutput: "listDatabricksAccountGroups"
};

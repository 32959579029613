/* @flow */

export const getGroup = {
  query: `query Q ($accountUri: String, $groupUri: String!,  $options: QueryOptions) {
        getGroup (accountUri: $accountUri, groupUri: $groupUri) {
            uri
            name
            description
            tags
            resource_name
            mailing_list
            responsible {
                name
                uri
            }
            identitiesList (options: $options) {
                total
                offset
                limit
                results {
                    uri
                    name
                    added_at
                }
            }
            account {
                uri
                name
                is_group_responsible_managed
            }
            creator {
                uri
                name
            }
        }
    }`,
  keyOutput: "getGroup"
};

export type GroupPropstypes = {
  uri: string,
  name: string,
  description: string,
  tags: string,
  mailing_list: string,
  resource_name: string,
  mailing_list: Array<string>,
  responsible: string,
  identities: Array<{
    uri: string,
    name: string,
    added_at: string
  }>,
  account: {
    uri: string,
    name: string
  },
  creator: {
    uri: string,
    name: string
  }
};

import React, { useState } from "react";

const SourceList = ({ sources }) => {
  const [showSources, setShowSources] = useState(false);

  const toggleShowSources = () => {
    setShowSources(!showSources);
  };

  return (
    <div>
      <div className="facet-category mb-2" key={"Sources"}>
        <div className="facet-title" onClick={toggleShowSources}>
          <h3>{showSources ? "Hide Sources" : "Show Sources"}</h3>
          <i
            className={`facet-chevron fas ${
              showSources ? "fa-chevron-down" : "fa-chevron-right"
            }`}
          >
            {" "}
          </i>
        </div>
      </div>
      {showSources && (
        <div>
          <ol>
            {sources.map(
              (source, index) =>
                source.url && (
                  <li key={index}>
                    {" "}
                    <a href={source.url} target="_blank">
                      {source.name}
                    </a>{" "}
                  </li>
                )
            )}
          </ol>
        </div>
      )}
    </div>
  );
};

export default SourceList;

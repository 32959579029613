/* @flow */
import React from "react"
import "./card.less"
import { connect } from "react-redux"
import withGoTo from "../../goToHOC"
import Links from "../../links"
import { formatDate } from "../../../utils/date"
import { updateMessage } from "../../globalNotifications/actions"
import withAppSync from "../../AppsyncHOC"
import routes from "../../routes"
import GraphQl from "../../../graphQL"
import DatasetProjectModal from "../components/DatasetProjectModal"
import logoS3 from "../../logos/s3-logo.png"
import DLLogo from "../../logos/deltalake-logo.png"
import logoRedshift from "../../logos/redshift-logo.png"
import { isRSSourceType, isS3SourceType } from "../../../utils/constants"

type propTypes = {
  dataset: Object,
  removeFromProject: Function,
  goTo: Function,
  api: GraphQl,
  project: Object,
  showDataFilters: boolean
}

type stateTypes = {
  projects: Array<Object>,
  openDatasetItemsModal: boolean
}

class DatasetCard extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      datasetFormat: null,
      openDatasetItemsModal: false
    }
  }

  componentDidMount() {
    this.getDatasetProjects()
    this.getDatasetFormat()
  }

  getDatasetProjects = () => {
    this.props.api.dataset
      .getDatasetProjects(this.props.dataset.uri)
      .then((projects) => {
        this.setState({
          projects
        })
      })
  }

  getDatasetFormat = async () => {
    try {
      const response = await this.props.api.dataset.listStages(
        this.props.dataset.uri,
        {
          offset: 0,
          limit: 1
        }
      )
      if (response.results[0].data_format_type) {
        this.setState({ datasetFormat: response.results[0].data_format_type })
      } else {
        this.setState({ datasetFormat: "" })
      }
    } catch (error) {
      this.setState({ datasetFormat: "" })
    }
  }

  onPreviewDataset = () =>
    this.props.goTo({
      route: `${routes.Dataset.View}#preview`,
      params: {
        uriDataset: this.props.dataset.uri
      }
    })

  onViewStages = () =>
    this.props.goTo({
      route: `${routes.Dataset.View}#stages`,
      params: {
        uriDataset: this.props.dataset.uri
      }
    })

  onDataFilters = () =>
    this.props.goTo({
      route: `${routes.Dataset.View}#datafilters`,
      params: {
        uriDataset: this.props.dataset.uri
      }
    })

  onIngestData = () =>
    this.props.goTo({
      route: `${routes.Dataset.View}#upload`,
      params: {
        uriDataset: this.props.dataset.uri
      }
    })

  onShareWithOthers = () =>
    this.props.goTo({
      route: `${routes.Dataset.View}#shares`,
      params: {
        uriDataset: this.props.dataset.uri
      }
    })

  onAvailableData = () => this.setState({ openDatasetItemsModal: true })

  render() {
    return (
      <div className={"datasets-card card-shadow card bg-white"}>
        <div className="card-dataset-header">
          <div className="card-dataset-begin">
            {this.state.datasetFormat !== null ? (
              this.state.datasetFormat === "DELTA" ? (
                <div className="card-dataset-begin-content">
                  <img
                    className="connector-logo-img mr-1"
                    src={DLLogo}
                    alt="S3"
                    width={18}
                    height={18}
                  />
                  <span className="text-header-datasource">Delta Lake</span>
                </div>
              ) : isS3SourceType(this.props.dataset.source_type) ? (
                <div className="card-dataset-begin-content">
                  <img
                    className="connector-logo-img mr-2"
                    src={logoS3}
                    alt="S3"
                    width={18}
                    height={18}
                  />
                  <span className="text-header-datasource">S3 Bucket</span>
                </div>
              ) : (
                isRSSourceType(this.props.dataset.source_type) && (
                  <div className="card-dataset-begin-content">
                    <img
                      className="connector-logo-img mr-2"
                      src={logoRedshift}
                      alt="Redshift Serverless"
                      width={18}
                      height={18}
                    />
                    <span className="text-header-datasource">
                      Redshift Serverless
                    </span>
                  </div>
                )
              )
            ) : (
              <i className="fas fa-sync-alt fa-spin" />
            )}
          </div>
          <div className="card-dataset-end">
            <i className="far fa-calendar-alt"></i>
            <span className="text-header-dataset">
              {formatDate(this.props.dataset.createdat, false)}
            </span>
          </div>
        </div>
        <div className="card-dataset-body">
          <div className="card-dataset-body-left">
            <div className={"dataset-card-name"}>
              <Links.Dataset.View uriDataset={this.props.dataset.uri}>
                {this.props.dataset.name}
              </Links.Dataset.View>
            </div>
            <div className="card-dataset-details">
              <p className="card-cart-description">
                {this.props.dataset.description &&
                  this.props.dataset.description.length > 50
                  ? `${this.props.dataset.description.substring(0, 50)}...`
                  : this.props.dataset.description}
              </p>
              {isS3SourceType(this.props.dataset.source_type) && (
                <div className="card-cart-role">
                  <i className="fab fa-aws"></i>
                  <span className="content-card-bucket">
                    Bucket:{" "}
                    {this.props.dataset.arn.replace("arn:aws:s3:::", "s3://")}
                  </span>
                </div>
              )}
              <div className="card-cart-role">
                <i className="fas fa-database mr-1"></i>
                <span className="content-card-bucket">
                  Database: {this.props.dataset.database}
                </span>
              </div>
              {isRSSourceType(this.props.dataset.source_type) && (
                <div className="card-cart-role">
                  <i className="fas fa-table mr-1"></i>
                  <span className="content-card-bucket">
                    Schema: {this.props.dataset.schema}
                  </span>
                </div>
              )}
              <div className="card-cart-role">
                <i className="fas fa-globe-europe"></i>
                <span className="content-card-region">
                  Region: {this.props.dataset.datasourceRegion}
                </span>
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">
            <div className="card-dataset-body-actions">
              {!this.props.project && (
                <div className="btn-cart" onClick={() => this.onViewStages()}>
                  <i className="fas fa-forward fa-spacing"></i>
                  <span className="text-actions">Explore Stages</span>
                </div>
              )}
              {!this.props.project &&
                this.props.showDataFilters &&
                isS3SourceType(this.props.dataset.source_type) && (
                  <div
                    className="btn-cart"
                    onClick={() => this.onDataFilters()}
                  >
                    <i className="fas fa-filter fa-spacing"></i>
                    <span className="text-actions">View Data Filters</span>
                  </div>
                )}
              {this.props.project && (
                <div
                  className="btn-cart"
                  onClick={() => this.onAvailableData()}
                >
                  <i className="fas fa-table fa-spacing"></i>
                  <span className="text-actions">View Available Data</span>
                </div>
              )}
              <div className="btn-cart" onClick={() => this.onPreviewDataset()}>
                <i className="fas fa-eye fa-spacing"></i>
                <span className="text-actions">Preview Dataset</span>
              </div>
              <div className="btn-cart" onClick={() => this.onIngestData()}>
                <i className="fas fa-file-upload fa-spacing"></i>
                <span className="text-actions">Upload New Data</span>
              </div>
              {!this.props.removeFromProject && (
                <div
                  className="btn-cart"
                  onClick={() => this.onShareWithOthers()}
                >
                  <i className="fas fa-share-alt fa-spacing"></i>
                  <span className="text-actions">Share With Others</span>
                </div>
              )}
              {this.props.removeFromProject && (
                <div
                  className="btn-cart"
                  onClick={() =>
                    this.props.removeFromProject(this.props.dataset)
                  }
                >
                  <i className="fas fa-trash fa-spacing"></i>
                  <span className="text-actions">Remove From Project</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-dataset-footer">
          <div className="card-dataset-begin">
            <i className="fas fa-user-friends" />
            <Links.Group.View
              uriGroup={this.props.dataset.datasourceOwner.uri}
              state={{ group: this.props.dataset.datasourceOwner }}
            >
              <span className="text-header-dataset">
                {this.props.dataset.datasourceOwner.name}
              </span>
            </Links.Group.View>
          </div>
          <div className="card-dataset-end">
            <div className="text-footer-metrics">
              Projects{" "}
              <span className="tag-metrics">
                {this.state.projects.length || "0"}
              </span>
              &nbsp; Type{" "}
              <span className="tag-metrics">{this.props.dataset.type}</span>
            </div>
          </div>
        </div>
        {this.props.project && this.state.openDatasetItemsModal && (
          <DatasetProjectModal
            api={this.props.api}
            dataset={this.props.dataset}
            project={this.props.project}
            open={this.state.openDatasetItemsModal}
            onClose={() => {
              this.setState({
                openDatasetItemsModal: false
              })
            }}
          />
        )}
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value))
  }
})

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasetCard)))

/* @flow */

import React from "react";
import { connect } from "react-redux";
import { HotTable } from "@handsontable/react";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import AsyncQuery from "../../asyncQuery";
import Error from "../../../components/error";
import "./preview.less";
import GraphQl from "../../../graphQL";
import SelectClassic from "../../../components/SelectClassic";
import { updateMessage } from "../../globalNotifications/actions";
import { isS3SourceType } from "../../../utils/constants";

const noDataFilterOption = {
  name: "No data filter",
  id: null
};

type propTypes = {
  api: GraphQl,
  dataset: Object,
  stage: Object,
  showDataFilters: boolean,
  showGlobalNotification: Function
};

type stateTypes = {
  availableDataFilters: Object,
  selectedDatafilter: Object,
  asyncQueryKey: number
};

class DatasetPreview extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      availableDataFilters: [],
      selectedDatafilter: null,
      asyncQueryKey: Math.random()
    };
  }

  componentDidMount() {
    if (this.props.showDataFilters) {
      this.listDatafilters();
    }
  }

  requestQuery = () => {
    return this.props.dataset.default_table
      ? this.props.api.dataset.getDatasetPreview(
          this.props.dataset.uri,
          this.props.dataset.default_table,
          this.props.stage.id
        )
      : this.props.api.dataset.getDatasetPreview(
          this.props.dataset.uri,
          null,
          this.props.stage.id
        );
  };

  getStatus = (requestId) =>
    this.props.api.dataset.getDatasetPreviewStatus(
      this.props.dataset.uri,
      requestId
    );

  getResult = (requestId) =>
    this.props.api.dataset.getDatasetPreviewResult(
      this.props.dataset.uri,
      requestId
    );

  listDatafilters = () => {
    const options = {
      limit: 99,
      offset: 0
    };

    return this.props.api.dataset
      .listDataFilters(this.props.dataset.uri, null, options)
      .then((dataFiltersList) => {
        let availableDataFilters = dataFiltersList.results.filter(
          (i) => i.stage.id === this.props.stage.id
        );
        availableDataFilters = [noDataFilterOption]
          .concat(availableDataFilters)
          .map((i) => {
            return {
              label: i.name,
              value: i.id
            };
          });
        this.setState({
          availableDataFilters
        });
        let selectedDatafilter = null;
        this.props.api.dataset
          .getStage(this.props.stage.id, this.props.dataset.uri)
          .then((stage) => {
            selectedDatafilter = availableDataFilters.find(
              (i) => i.value === stage.preview_data_filter_id
            );
            this.setState({
              selectedDatafilter
            });
          });
      });
  };

  getStage = () => {
    this.props.api.dataset
      .getStage(this.props.stage.id, this.props.stage.dataset_uri)
      .then((stage) => {
        this.setState((prevState) => {
          const availableDataFilters = Object.assign(
            {},
            prevState.availableDataFilters
          );
          return {
            selectedDatafilter: availableDataFilters.find(
              (i) => i.value === stage.preview_data_filter_id
            )
          };
        });
      });
  };

  handleDatafilterChange = (option) => {
    this.setState({ selectedDatafilter: option }, () => {
      this.applyPreviewDatafilterToStage();
    });
  };

  async applyPreviewDatafilterToStage() {
    const input = {
      preview_data_filter_id: this.state.selectedDatafilter.value
    };
    this.props.showGlobalNotification({
      message: "Updating Data Preview...",
      type: "info"
    });
    await this.props.api.dataset.updateStage(
      this.props.stage.id,
      input,
      this.props.dataset.uri
    );
    this.setState({
      asyncQueryKey: Math.random()
    });
  }

  render() {
    if (this.props.dataset.access === "other") {
      return (
        <div className="noaccess-container">
          You do not have rights to access the preview of the dataset. Make a
          request access to benefit from this feature
        </div>
      );
    }

    return (
      <div className="preview-container">
        {this.props.stage && this.props.showDataFilters && isS3SourceType(this.props.stage.source_type) && (
          <div className='datafilter-info'>
            <span className='text-uppercase'>
              <i className='fas fa-filter datafilter-info-team' />
              Data Filter&nbsp;&nbsp;
              <div
                style={{
                    width: '300px',
                    zIndex: 99,
                    display: 'inline-block',
                    position: 'relative'
                }}
              >
                <SelectClassic
                  placeholder={'Select Data Filter'}
                  options={this.state.availableDataFilters}
                  selectedOption={this.state.selectedDatafilter}
                  onSelectOption={this.handleDatafilterChange}
                />
              </div>
            </span>
          </div>
        )}
        <div>
          <AsyncQuery
            key={this.state.asyncQueryKey}
            requestQuery={this.requestQuery}
            getStatus={this.getStatus}
            getResult={this.getResult}
            onResult={(result) => {
              const metadata = result.schema.map((field) => field.name);
              let data;
              if (isS3SourceType(this.props.stage.source_type)) {
                data = result.records
                    .slice(1)
                    .map((item) => metadata.map((keyMetadata) => (keyMetadata in item ? item[keyMetadata] : '')));
              } else {
                data = result.records
                    .map((item) => metadata.map((keyMetadata) => (keyMetadata in item ? item[keyMetadata] : '')));
              }
              return (
                <div className='content'>
                  <HotTable colHeaders={metadata} data={data} width={800} height={500} readOnly />
                </div>
              );
            }}
            onPending={() => <Loading message={`Preview for dataset ${this.props.dataset.name} in progress`} />}
            onError={(error) => {
              if (error === 'This dataset has no backed data') {
                return <div> No data to preview. It probably means that this dataset has no stage yet.</div>;
              }
              return <Error title='This dataset cannot be previewed' error={error} path={'DatasetPreview'} />;
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DatasetPreview));

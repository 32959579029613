export const listPlatformGroups = {
  query: `query Q ($platformUri:String!, , $options: QueryOptions) {
        listPlatformGroups(platformUri:$platformUri, options: $options) {
            total
            offset
            limit
            results {
                name
                uri
                description
                account {
                    uri
                    name
                }
                carts {
                    name
                }
                mailing_list
            }
        }
      }
    `,
  keyOutput: "listPlatformGroups"
};

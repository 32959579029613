import { askGenAICatalogChatBot } from './mutationAskGenAICatalogChatBot';

class CatalogAPI {
    constructor(fetchAppSync) {
        this.fetchAppSync = fetchAppSync;
    }

    askGenAICatalogChatBot(search = {}) {
        return this.fetchAppSync(askGenAICatalogChatBot, { search });
    }
}

export default CatalogAPI;

export const listDatabricksAccountWorkspaces = {
  query: `query Q ($uuid:String!, $accountUri: String, $options: QueryOptions) {
        listDatabricksAccountWorkspaces(uuid: $uuid, accountUri: $accountUri options: $options) {
            results {
                dbx_workspace_url
                dbx_workspace_name
                dbx_workspace_id
                dbx_warehouse_id
                dbx_account_uuid
            }
            total
            offset
            limit
        }
      }
    `,
  keyOutput: "listDatabricksAccountWorkspaces"
};

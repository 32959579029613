/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import DatasourceCard from "../users/components/DatasourceCard";
import routes from "../routes";
import Modal from "../../components/modal";
import Error from "../../components/error";
import Breadcrumb from "../../components/breadcrumb";
import "./view.less";
import GraphQl from "../../graphQL";
import config from "../../config";
import Paginator from "../../components/paginator";
import DetachPlaygroundModal from "./DetachPlaygroundModal";
import TeamsLogo from "./microsoft-teams-icon.webp";
import MailLogo from "./mailicon.png";
import Tooltip from "@mui/material/Tooltip";

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  },
  goTo: Function,
  api: GraphQl
};

type stateTypes = {
    ready: boolean,
    playground: Object,
    datasources: Array<Object>,
    account: Object,
    playgroundModal: Object,
    errorPlaygroundModal: Object,
    error: boolean,
    datasourceReady: boolean,
    reset: boolean,
    datasourcesTotal: number,
    datasourcesLimit: number,
    keywords?: string,
    openDetachPlaygroundModal: boolean,
    isAdmin: boolean,
    groups: Array<object>,
    totalGroups: Number
};

class PlaygroundView extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

    constructor(props: propTypes) {
        super(props);
        this.state = {
            ready: false,
            datasourceReady: false,
            reset: false,
            datasourcesTotal: 0,
            datasourcesLimit: 10,
            playground: {},
            datasources: [],
            account: {},
            playgroundModal: undefined,
            errorPlaygroundModal: undefined,
            error: false,
            openDetachPlaygroundModal: false,
            isAdmin: false,
            groups: [],
            totalGroups: 0
        };
    }

    componentDidMount() {
        this.getPlayground();
        this.listDatasources();
        this.getGroupAdmin();
    }

  listDatasources = (offset = 0) => {
    this.setState({ datasourceReady: false });
    const options = { offset, search: {} };
    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }
    return this.props.api.playground
      .listPlatformDatasources(this.props.match.params.uriPlayground, options)
      .then((datasourcesResponse) => {
        this.setState({
          datasourceReady: true,
          reset: false,
          datasources: datasourcesResponse.results,
          datasourcesTotal: datasourcesResponse.total,
          datasourcesLimit: datasourcesResponse.limit
        });
        return datasourcesResponse.results;
      })
      .catch((error) => {
        this.setState({ error, reset: false });
        return [];
      });
  };

  getPlayground = () =>
    this.props.api.playground
      .get(
        this.props.match.params.uriAccount,
        this.props.match.params.uriPlayground
      )
      .then((response) => {
        if (!response) return false;
        this.setState({
          ready: true,
          playground: response.playground,
          account: response.account
        });
        return response;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

    getGroupAdmin = () => 
        this.props.api.playground
      .listGroups(this.props.match.params.accountUri, this.props.match.params.uriPlayground, { offset: 0, limit: 999 })
      .then((response) => {
        if (!response) return [];
        const groups = response && response.results ? response.results : [];
        this.setState((prevState) => ({
          isFetchingGroups: false,
          groups: prevState.groups.concat(groups),
          totalGroups: response.total
        }));
        return groups.map((p) => ({ value: p.uri, label: p.name }));
      })
      .catch((error) => {
        this.setState({
          error,
          Groups: [],
        });
      });
      
      
    onChangeInput = (e: Object) => {
        const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          reset: true,
          keywords
        },
        this.listDatasources
      );
    }, 500);
  };

  openDeletePlaygroundModal = (uriAccount: string, uriPlayground: string) =>
    this.setState({
      playgroundModal: { uriAccount, uriPlayground }
    });

  closeDeletePlaygroundModal = () =>
    this.setState({
      playgroundModal: null,
      errorPlaygroundModal: null
    });

  displayModals() {
    if (this.state.playgroundModal) {
      return this.deletePlaygroundModal();
    }

    return null;
  }

  closeModal() {
    this.setState({ openDetachPlaygroundModal: false });
  }

  deletePlaygroundModal() {
    const { playgroundModal, account } = this.state;

    const { goTo } = this.props;

    return (
      <Modal
        title="Delete this playground"
        body={"Are you sure ?"}
        errorMessage={this.state.errorPlaygroundModal}
        actions={[
          <button
            key={Math.random()}
            type="button"
            className="butn"
            onClick={() => this.closeDeletePlaygroundModal()}
          >
            Close
          </button>,
          <button
            key={Math.random()}
            type="button"
            className="butn butn-delete"
            onClick={() => {
              this.props.api.playground
                .delete(
                  playgroundModal.uriAccount,
                  playgroundModal.uriPlayground
                )
                .then(() => {
                  goTo({
                    route: routes.Playground.List,
                    params: {
                      uriAccount: playgroundModal.uriAccount
                    },
                    state: {
                      account
                    }
                  });
                })
                .catch((error) => {
                  this.setState({ errorPlaygroundModal: error });
                });
            }}
          >
            Delete
          </button>
        ]}
      />
    );
  }

    Administrator = () => {
        const suffix = 'Environment Administrators Group';
        const adminUser = this.state.groups.find(admin => admin.name.endsWith(suffix));
        let chatUrl = `https://teams.microsoft.com/l/chat/0/0?users=$`;

        if (!adminUser)
            return;

        else if(adminUser.name && adminUser.mailing_list.length === 0) 
            return(
                <div>Administrator Group: {(adminUser.name)}</div>
        ); 

        else
            return(
                <div>Administrator Group: {(adminUser.name)}
                <a href={`mailto:${adminUser.mailing_list}`}>
                <img className="logo" src={MailLogo} title="Contact the organization administrator group by mail" alt="mailto" />
                </a>
                <a href= {chatUrl + adminUser.mailing_list.join(',')} >
                <img className="logo" src={TeamsLogo} title="Contact the organization administrator group by Teams chat" alt="teams" />
                 </a>    
                </div>
        );
    };     

    actions = () => {
        const {
            match,
            goTo,
        } = this.props;

    return (
      <div className="actions">
        <div>
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              goTo({
                route: routes.Playground.Groups,
                params: {
                  uriPlayground: match.params.uriPlayground,
                  uriAccount: match.params.uriAccount
                },
                state: { playground: this.state.playground }
              })
            }
          >
            Manage Groups
          </button>
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              goTo({
                route: routes.Playground.CreateDatasource,
                params: {
                  uriPlayground: match.params.uriPlayground,
                  uriAccount: match.params.uriAccount
                },
                state: {
                  playground: this.state.playground
                }
              })
            }
          >
            <span className="btn-text">Create datasource</span>
          </button>
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              goTo({
                route: routes.Playground.Admin,
                params: {
                  uriPlayground: match.params.uriPlayground,
                  uriAccount: match.params.uriAccount
                }
              })
            }
          >
            Administration
          </button>
        </div>
        <div>
          <button
            className="butn butn"
            type="button"
            onClick={() =>
              goTo({
                route: routes.Playground.Edit,
                params: {
                  uriPlayground: match.params.uriPlayground,
                  uriAccount: match.params.uriAccount
                },
                state: {
                  playground: this.state.playground
                }
              })
            }
          >
            <span className="btn-text">Edit</span>
          </button>
          <button
            className="butn butn-delete"
            type="button"
            onClick={() =>
              this.openDeletePlaygroundModal(
                match.params.uriAccount,
                match.params.uriPlayground
              )
            }
          >
            <span className="btn-text">Delete</span>
          </button>
        </div>
      </div>
    );
  };

  render = () => {
    if (this.state.error)
      return <Error error={this.state.error} path={"PlaygroundView"} />;
    if (!this.state.ready) return <Loading message={"Environment"} />;
    const classEnvironment = {
      NoProd: "NoProd",
      "No-Prod": "NoProd",
      Prod: "Prod"
    };
    return (
      <div className="playground-view">
        <div className="header">
          <div className="title-line">
            <Breadcrumb
              state={{ account: this.state.account }}
              view={`${this.state.playground.name || ""} - Environment`}
              helpOn={{
                content: (
                  <div>
                    <div>- Environment</div>
                    <div>Manage your environment.</div>
                  </div>
                ),
                helpCenterLink: `${config.HELP_CENTER}`
              }}
            />
          </div>
          {this.actions()}
        </div>

        <ul className="info">
          <li>URI: {this.state.playground.uri}</li>
          <li>Name: {this.state.playground.name}</li>
          <li>Description: {this.state.playground.description}</li>
          <li>AWS account: {this.state.playground.aws}</li>
          <li>GBUS: {this.state.playground.engieEntity.gbus}</li>
          <li>ORG ID: {this.state.playground.engieEntity.org_id}</li>
          <li>Entity Scope: {this.state.playground.engieEntity.scope}</li>
          <li>
            Entity Sub Scope: {this.state.playground.engieEntity.sub_scope}
          </li>
          <li>
            Stages auto refresh:{" "}
            <span
              className={
                this.state.playground.auto_refresh_stages
                  ? "refresh-auto-enabled"
                  : "refresh-auto-disabled"
              }
            >
              {this.state.playground.auto_refresh_stages
                ? "Enabled"
                : "Disabled"}
            </span>
          </li>
          <li> {this.Administrator()}</li>
          <li>
            <div
              className={`dataset-card-environment ${
                classEnvironment[this.state.playground.environment]
              }`}
            >
              <span>{this.state.playground.environment}</span>
            </div>
          </li>
        </ul>

        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search Datasource"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
        </div>

        {!this.state.ready && <Loading message={"Environment view"} />}
        {this.state.reset && <Loading message="" />}

        {!this.state.reset && (
          <div className="card-container">
            <Paginator
              isLoadingPage={!this.state.datasourceReady}
              limit={this.state.datasourcesLimit}
              initialOffset={0}
              totalCount={this.state.datasourcesTotal}
              list={this.state.datasources}
              componentRender={(datasource) => (
                <DatasourceCard key={datasource.uri} datasource={datasource} />
              )}
              loadPage={this.listDatasources}
              onNoResult={() => (
                <div className="total-search-results">No datasource found</div>
              )}
            />
          </div>
        )}

        {this.state.openDetachPlaygroundModal && (
          <DetachPlaygroundModal
            playground={this.state.playground}
            hide={() => {
              this.closeModal();
            }}
            goBack={() =>
              this.props.goTo({
                route: routes.Playground.List,
                params: {
                  uriPlayground: this.props.match.params.uriPlayground,
                  uriAccount: this.props.match.params.uriAccount
                },
                state: { playground: this.state.playground }
              })
            }
          />
        )}

        {this.displayModals()}
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(PlaygroundView));
